.App {
  text-align: center;
  color: white;
  font-family: "Gilroy";
}

@font-face {
  font-family: "Gilroy";
  src: url('../fonts/Gilroy-Regular.woff');
  font-weight: lighter;
  font-style: italic;
}

.local-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.blue-link {
  color: dodgerblue !important;
}

.paragraph-spacing {
  margin-top: 2em;
}